















































import {Component, Vue} from "vue-property-decorator";
import ResourceCategory from "@/models/ResourceCategory";
import ResourceCategoryService from "@/services/ResourceCategoryService";

@Component
export default class SecretaryView extends Vue {
    categories: ResourceCategory[] = []
    resourceCategoriesLoading: boolean = false


    created() {
        ResourceCategoryService.getResourceCategories(this, this.categories)
    }

}
